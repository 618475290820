import '../styles/index.scss';

let slides = document.querySelector('#slides');
let menu = document.querySelector('#menu');

const VIDEO_SLIDE_NUMBER = 2;
const MAX_SLIDE_NUMBER = 5;

if (slides && menu) {
    let slidesCount = slides.querySelectorAll('.slide').length;

    let nextSlideButton = document.querySelector('#slides-next');
    if (nextSlideButton) {
        nextSlideButton.addEventListener('click', function () {
            let activeSlide = slides.dataset.activeSlide;
            if (activeSlide < slidesCount - 1) {
                slides.classList.remove('active-' + activeSlide);
                activeSlide++;
                slides.classList.add('active-' + activeSlide);
                slides.dataset.activeSlide = activeSlide;

                slideEvent(activeSlide);
            }
        });
    }

    let prevSlideButton = document.querySelector('#slides-prev');
    if (prevSlideButton) {
        prevSlideButton.addEventListener('click', function () {
            let activeSlide = slides.dataset.activeSlide;
            if (activeSlide > 0) {
                slides.classList.remove('active-' + activeSlide);
                activeSlide--;
                slides.classList.add('active-' + activeSlide);
                slides.dataset.activeSlide = activeSlide;

                slideEvent(activeSlide);
            }
        });
    }

    let pauseVideoTimeout;

    let slideEvent = function (slideNumber) {
        let video = document.getElementById('diamond-video');

        if (video) {
            if (slideNumber == VIDEO_SLIDE_NUMBER) {
                if(pauseVideoTimeout) {
                    clearTimeout(pauseVideoTimeout);
                }

                video.pause();
                video.currentTime = 0;
                video.play();
            } else {
                if(pauseVideoTimeout) {
                    clearTimeout(pauseVideoTimeout);
                }

                pauseVideoTimeout = setTimeout(function () {
                    video.pause();
                    video.currentTime = 0;
                }, 1000);
            }
        }

        prevSlideButton.classList.toggle('is-hidden', slideNumber == 0 || slideNumber > MAX_SLIDE_NUMBER);
        nextSlideButton.classList.toggle('is-hidden', slideNumber == slidesCount - 1 || slideNumber >= MAX_SLIDE_NUMBER);
        menu.classList.toggle('is-hidden', slideNumber < 5);
    };

    let setActiveSlide = function (slideNumber) {
        for (let i = 0; i <= slidesCount; i++) {
            slides.classList.toggle('active-' + i, slideNumber == i);
        }
        slides.dataset.activeSlide = slideNumber;
        slideEvent(slideNumber);
    };

    let caseImageSlides = document.querySelector('#case-image-slider-slides');

    if (caseImageSlides) {
        let caseImageSlideDots = document.querySelectorAll('#case-image-slider-controls .slider-dot');
        caseImageSlideDots.forEach(function (dot) {
            dot.addEventListener('click', function () {
                document.querySelectorAll('#case-image-slider-controls .slider-dot').forEach(function (dot) {
                    dot.classList.remove('active');
                });

                dot.classList.add('active');

                for (let i = 0; i < caseImageSlideDots.length; i++) {
                    caseImageSlides.classList.toggle('active-' + i, dot.dataset.slideNumber == i);
                }
            });
        });
    }

    let menuItems = menu.querySelectorAll('a');
    menuItems.forEach(function (menuItem) {
        menuItem.addEventListener('click', function (e) {
            setTimeout(function () {
                setActiveSlide(e.target.dataset.slideNumber);
            }, 10);

            menuItems.forEach(function (menuItem) {
                menuItem.classList.remove('active');
            });
            e.target.classList.add('active');
        });
    });

    document.querySelector('h1').addEventListener('click', function () {
        setActiveSlide(1);
    });

    let processSlides = document.querySelector('#process-image-slider-slides');

    if (processSlides) {
        let processSlidesCount = processSlides.querySelectorAll('.process-image-slide').length;

        let nextProcessSlideButton = document.querySelector('#process-slides-next');
        if (nextProcessSlideButton) {
            nextProcessSlideButton.addEventListener('click', function () {
                let activeSlide = processSlides.dataset.activeSlide;
                if (activeSlide < processSlidesCount - 1) {
                    processSlides.classList.remove('active-' + activeSlide);
                    activeSlide++;
                    processSlides.classList.add('active-' + activeSlide);
                    processSlides.dataset.activeSlide = activeSlide;

                    prevProcessSlideButton.classList.toggle('is-hidden', activeSlide == 0);
                    nextProcessSlideButton.classList.toggle('is-hidden', activeSlide == processSlidesCount - 1);
                }
            });
        }

        let prevProcessSlideButton = document.querySelector('#process-slides-prev');
        if (prevProcessSlideButton) {
            prevProcessSlideButton.addEventListener('click', function () {
                let activeSlide = processSlides.dataset.activeSlide;
                if (activeSlide > 0) {
                    processSlides.classList.remove('active-' + activeSlide);
                    activeSlide--;
                    processSlides.classList.add('active-' + activeSlide);
                    processSlides.dataset.activeSlide = activeSlide;

                    prevProcessSlideButton.classList.toggle('is-hidden', activeSlide == 0);
                    nextProcessSlideButton.classList.toggle('is-hidden', activeSlide == processSlidesCount - 1);
                }
            });
        }
    }
}
